import BaseChart from './_base';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import extend from 'lodash/extend';
import defaultsDeep from 'lodash/defaultsDeep';
import {
  highchartsNavigation,
  highchartsTooltipOptions,
  highchartsDownloadContextButton,
  highchartsLegend
} from './highcharts_options';

Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);
const createID = (str) => {
  const filterForm = $('#j-analysis-filters');
  let seriesName;

  if (filterForm.length > 0) {
    if (str.match(/gross.*total/i)) {
      seriesName = 'display_gross_total_return';
    } else if (str.match(/(LC.*net.*total)/i)) {
      seriesName = 'display_lc_equivalent_total_return';
    } else if (str.match(/fx.*impact/i)) {
      seriesName = 'display_fx_impact';
    } else if (str.match(/(net.*total|total)/i)) {
      seriesName = 'display_total_return';
    } else if (str.match(/capital/i)) {
      seriesName = 'display_capital_growth';
    } else if (str.match(/income/i)) {
      seriesName = 'display_income_return';
    } else {
      seriesName = str.toLowerCase().replace(/[ :]/g, '_');
    }
  } else {
    seriesName = str.toLowerCase().replace(/[ :]/g, '_');
  }

  return '#' + seriesName;
};
export default class Line extends BaseChart {
  constructor (container, props) {
    super(container, props);
    // The form is not always available so we need to set with fallbacks
    const filterForm = $('#j-analysis-filters');
    const rolling = filterForm.find('#rolling').val() === 'true';
    const chartType = filterForm.find('#chart_type').val() === 'column' ? 'column' : 'line';
    // Check to see if chart has multiple states
    // { [stateKey]: { series..., title..., etc. } }
    if (props.dataStates) {
      const stateKey = rolling ? 1 : 0;
      this.data = props.dataStates[Object.keys(props.dataStates)[stateKey]];
      this.dataStates = props.dataStates;
    } else {
      this.data = props.data;
    }
    this.options = extend({
      labelRotation: -45,
      chart: { events: {} },
      showContextButton: false,
      showTitle: true,
      showGridLines: false,
      showAxisLines: false,
      animation: true,
      showLegend: true,
      showPoints: false,
      enableMouseTracking: true,
      margin: {},
      dimensions: {},
      legend: {
        show: true,
        alignment: 'center',
        cords: { x: 0, y: 0 }
      },
      colors: []
    }, props.options);
    this.chartSeriesType = chartType || 'line';
    if (this.options.chart.events) {
      this.options.chart.events.load = this.initStateToggles.bind(this);
    }
    this.init();
    return this.chart;
  }

  renderChart () {
    const theme = window.App.theme;
    const _options = this.options;
    const labelStyle = {
      color: theme.colors.defaults.chart.labelsBright,
      fontSize: '12px',
      fontFamily: 'Arial'
    };
    const baseAxisStyle = {
      allowDecimals: false,
      minorGridLineColor: 'transparent',
      minorGridLineWidth: 0,
      tickPixelInterval: 30,
      minorTickLength: 0,
      minPadding: 0,
      maxPadding: 0,
      tickLength: 0
    };

    // For persistence, set series values according to form values
    const filterForm = $('#j-analysis-filters');
    const filterAside = $('.j-filter-results');
    const chartType = filterForm.find('#chart_type').val() === 'column' && !filterAside.hasClass('with-expanded-aside') ? 'column' : 'line';
    const persistentSeries = this.data.series.map((series) => {
      const inputId = createID(series.name);
      series.visible = filterForm.length > 0 ? filterForm.find(inputId).val() === 'true' : true;
      if (!/(Net )?Total Return/.test(series.name) && series.allowTypeToggle !== false) {
        series.type = chartType;
      }
      return series;
    });

    const chartOptions = defaultsDeep(_options, {
      exporting: {
        buttons: {
          contextButton: highchartsDownloadContextButton(_options.showContextButton)
        },
        fallbackToExportServer: false,
        url: 'disabled'
      },
      chart: {
        type: 'line',
        style: {
          fontFamily: '"Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif',
          color: theme.colors.defaults.chart.labelsDim
        },
        height: _options.dimensions.height || this.container.clientHeight || null,
        width: _options.dimensions.width || this.container.clientWidth || null,
        marginTop: _options.margin.top,
        marginBottom: _options.margin.bottom,
        marginLeft: _options.margin.left,
        marginRight: _options.margin.right
      },
      title: { text: _options.showTitle ? (this.data.title.text || this.props.title) : null },
      tooltip: extend({
        formatter: function (tooltip) {
          // If you set index so the lines appear in the correct order in the chart,
          // - They may be in a different order than the legendIndex
          // - This effectively hardcodes the tooltip order when these 3 series are the chart.
          if ([3, 4].indexOf(this.points.length) >= 0) {
            const seriesOrder = [/^(net.*total|total)/i, /^lc/i, /capital/i, /income/i, /gross.*total/i];
            const newPoints = [];
            seriesOrder.forEach((regex) => {
              this.points.forEach((point) => {
                if (point.series.name.match(regex)) {
                  newPoints.push(point);
                }
              });
            });
            if ([3, 4].indexOf(newPoints.length) >= 0) {
              this.points = newPoints;
            }
          }

          // Call parent formatter with sorted points.
          return tooltip.defaultFormatter.call(this, tooltip);
        },
        pointFormat: `
          <span class='b-chart-labels__label b-chart-labels__label--flex b-chart-labels__label--custom-swatch'>
            <span class='b-chart-labels__swatch' style="background:{series.color}" > </span>
            <span>
              {series.name}:
            </span>
            <span class='b-chart-labels__value'>{point.y}%</span>
          </span>
        `
      }, highchartsTooltipOptions()),
      plotOptions: {
        column: {
          stacking: 'normal',
          groupPadding: 0.1,
          borderWidth: 0,
          groupZPadding: 0
        },
        series: {
          animation: _options.animation,
          enableMouseTracking: _options.enableMouseTracking,
          events: {
            legendItemClick: (event) => {
              const filterForm = $('#j-analysis-filters');
              if (filterForm.length === 0) { return; }
              event.preventDefault();
              const inputId = createID(event.target.name);
              filterForm.find(inputId).val(!event.target.visible);
              this.chart.legend.allItems.forEach(item => {
                const legendID = createID(item.name);
                if (filterForm.find(legendID).val() === 'true') {
                  item.show();
                } else {
                  item.hide();
                }
              });
            }
          }
        },
        line: {
          lineWidth: 3,
          marker: _options.showPoints
            ? extend({ symbol: 'circle', radius: 5 }, _options.marker)
            : { enabled: false }
        }
      },
      colors: _options.colors.length ? _options.colors.length : this.data.colors,
      xAxis: extend({
        title: {
          text: _options.xAxis && _options.xAxis.title ? _options.xAxis.title : '',
          style: {
            color: theme.colors.defaults.chart.labelsBright,
            fontFamily: 'Arial'
          },
          margin: 0
        },
        labels: {
          style: labelStyle,
          rotation: _options.labelRotation
        },
        categories: this.data.xAxis.categories,
        tickColor: '#FFFFFF',
        lineColor: _options.xAxis && _options.xAxis.color ? _options.xAxis.color : 'transparent',
        lineWidth: _options.xAxis && _options.xAxis.showAxisLines ? 1 : 0,
        gridLineColor: _options.xAxis && _options.xAxis.showGridLines ? '#E2E2E2' : 'transparent',
        gridLineWidth: _options.xAxis && _options.xAxis.showGridLines ? 1 : 0
      }, baseAxisStyle),
      yAxis: extend({
        title: {
          text: _options.yAxis && _options.yAxis.title ? _options.yAxis.title : '',
          style: {
            color: theme.colors.defaults.chart.labelsBright,
            fontFamily: 'Arial',
            margin: 20
          },
          margin: 0
        },
        labels: {
          style: labelStyle,
          formatter: function () {
            return `${this.value}%`;
          }
        },
        lineColor: _options.yAxis && _options.yAxis.color ? _options.yAxis.color : 'transparent',
        lineWidth: _options.yAxis && _options.yAxis.showAxisLines ? 1 : 0,
        gridLineColor: _options.yAxis && _options.yAxis.showGridLines ? '#E2E2E2' : 'transparent',
        gridLineWidth: _options.yAxis && _options.yAxis.showGridLines ? 1 : 0
      }, baseAxisStyle),
      credits: {
        enabled: false
      },
      series: persistentSeries,
      legend: extend(
        highchartsLegend(_options.legend.show, _options.legend.alignment, false, _options.legend.cords, _options.legend.symbolWidth), {
          useHTML: true,
          labelFormatter: function () {
            let legendIcon;
            if (this.visible) {
              legendIcon = ' <i class="icon--sm icon-inrev-legend-show" />';
            } else {
              legendIcon = ' <i class="icon--sm icon-inrev-legend-hide" />';
            }
            return this.name + legendIcon;
          }
        }),
      navigation: highchartsNavigation(),
      events: {
        render: () => {
          this.updatePoints();
        }
      }
    });
    this.chart = Highcharts.chart(this.container, chartOptions);
    return this.chart;
  }

  initStateToggles () {
    const seriesWithToggles = this.data.series.filter(s => s.allowTypeToggle);
    if (!seriesWithToggles.length && !this.dataStates) return;

    const $chartContainer = $(this.container);
    const toggleContainerClass = 'c-chart__toggle-container';
    $chartContainer.prepend(`<div class='${toggleContainerClass}'></div>`);

    const addChartStateToggles = ({ toggleType = 'data', source }) => {
      const linkClass = `j-chart-${toggleType}-toggle`;
      const toggleIsData = toggleType === 'data';
      const filterForm = $('#j-analysis-filters');
      const toggles = Object.keys(source).map((t, i) => {
        let iconClass = toggleIsData ? 'line' : t;
        if (t.match(/annual/i)) {
          iconClass += '-rolling';
        }
        let active = null;
        if (toggleIsData) {
          const rolling = filterForm.find('#rolling').val() === 'true';
          active = (rolling === /rolling/.test(iconClass));
        } else {
          const chartType = filterForm.find('#chart_type').val();
          active = (t === chartType);
        }
        return `
          <a href='#' class='c-chart__toggle-link ${linkClass} ${active ? 'active' : ''} btn c-button--outline c-button--light mt-0 mb-0 p-3' data-state='${t}'>
            <i class='icon-inrev-chart-${iconClass} mr-1'></i>
            ${source[t].title.text}
          </a>
        `;
      });
      const toggleContainerId = `${toggleType}-toggles`;
      const alignment = toggleIsData ? 'left' : 'right';
      const marginClass = toggleIsData ? 'mr-auto' : 'ml-auto';
      $chartContainer.find(`.${toggleContainerClass}`).append(`
        <div id='${toggleContainerId}' class='c-chart__toggle-group ${alignment} b-button-group b-button-group--toggle b-button-group--justified btn-group btn-group-toggle ${marginClass}'>
          ${toggles.join('')}
        </div>
      `);
      const $toggleLinks = $(`#${toggleContainerId}`).find(`.${linkClass}`);
      if (toggleType === 'data') {
        // Toggle Rolling vs NotRolling
        $toggleLinks.on('click', (event) => {
          const $target = $(event.currentTarget);
          if ($target.hasClass('active')) return;
          $(`.${linkClass}`).removeClass('active');

          const filterForm = $('#j-analysis-filters');
          const rolling = filterForm.find('#rolling');
          rolling.val(rolling.val() === 'true' ? 'false' : 'true');

          const newStateKey = event.currentTarget.dataset.state;
          const newChartConfig = source[newStateKey];
          const newSeries = newChartConfig.series;

          // Update series
          const typeInfo = newSeries.map((s, i) => {
            return this.data.series[i].allowTypeToggle ? this.chartSeriesType : 'line';
          });

          const length = this.chart.series.length;
          for (let i = 0; i < length; i++) {
            // Removing from the series reorders the indexes, so this needs to happen separately.
            this.chart.series[0].remove(true);
          }

          newSeries.forEach((s, i) => {
            s.type = typeInfo[i] || 'line';
            s.zIndex = s.zIndex || s.index;
            if (!s.zIndex && s.type === 'line') {
              s.zIndex = 1000;
            }
            const inputId = createID(s.name);
            s.visible = filterForm.find(inputId).val() === 'true';
            this.chart.addSeries(s);
          });
          this.chart.xAxis[0].setCategories(newChartConfig.xAxis.categories);

          // Update chart
          this.chart.update(newChartConfig);
          $target.addClass('active');
          this.data = newChartConfig;
        });
      } else {
        // Toggle Line vs StackedBar
        $toggleLinks.on('click', (event) => {
          const $target = $(event.currentTarget);
          if ($target.hasClass('active')) return;
          $(`.${linkClass}`).removeClass('active');

          const filterForm = $('#j-analysis-filters');
          const chartType = filterForm.find('#chart_type');
          chartType.val(chartType.val() === 'line' ? 'column' : 'line');

          const newType = chartType.val() === 'line' ? 'line' : 'column';
          this.chartSeriesType = newType;

          this.data.series.forEach((originalSeries, i) => {
            if (originalSeries.allowTypeToggle) {
              const currentSeries = this.chart.series.find(chartSeries =>
                chartSeries.name === originalSeries.name
              );
              currentSeries && currentSeries.update({ type: newType });
            }
          });
          $target.addClass('active');
        });
      }
    };

    // Add toggles for datasets
    if (this.dataStates) {
      addChartStateToggles({ source: this.dataStates });
    }
    // Add toggles for chart type
    if (seriesWithToggles.length) {
      addChartStateToggles({
        toggleType: 'type',
        source: {
          line: { title: { text: 'Line' } },
          column: { title: { text: 'Bar' } }
        }
      });
    }
  }
}
